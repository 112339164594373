.color-red-50 { color: #ffebee; }
.bg-red-50 { background-color: #ffebee; }
.border-red-50 { border-color: #ffebee; }
.fill-red-50 { fill: #ffebee; }
.stroke-red-50 { stroke: #ffebee; }
.color-red-100 { color: #ffcdd2; }
.bg-red-100 { background-color: #ffcdd2; }
.border-red-100 { border-color: #ffcdd2; }
.fill-red-100 { fill: #ffcdd2; }
.stroke-red-100 { stroke: #ffcdd2; }
.color-red-200 { color: #ef9a9a; }
.bg-red-200 { background-color: #ef9a9a; }
.border-red-200 { border-color: #ef9a9a; }
.fill-red-200 { fill: #ef9a9a; }
.stroke-red-200 { stroke: #ef9a9a; }
.color-red-300 { color: #e57373; }
.bg-red-300 { background-color: #e57373; }
.border-red-300 { border-color: #e57373; }
.fill-red-300 { fill: #e57373; }
.stroke-red-300 { stroke: #e57373; }
.color-red-400 { color: #ef5350; }
.bg-red-400 { background-color: #ef5350; }
.border-red-400 { border-color: #ef5350; }
.fill-red-400 { fill: #ef5350; }
.stroke-red-400 { stroke: #ef5350; }
.color-red-500 { color: #f44336; }
.bg-red-500 { background-color: #f44336; }
.border-red-500 { border-color: #f44336; }
.fill-red-500 { fill: #f44336; }
.stroke-red-500 { stroke: #f44336; }
.color-red-600 { color: #e53935; }
.bg-red-600 { background-color: #e53935; }
.border-red-600 { border-color: #e53935; }
.fill-red-600 { fill: #e53935; }
.stroke-red-600 { stroke: #e53935; }
.color-red-700 { color: #d32f2f; }
.bg-red-700 { background-color: #d32f2f; }
.border-red-700 { border-color: #d32f2f; }
.fill-red-700 { fill: #d32f2f; }
.stroke-red-700 { stroke: #d32f2f; }
.color-red-800 { color: #c62828; }
.bg-red-800 { background-color: #c62828; }
.border-red-800 { border-color: #c62828; }
.fill-red-800 { fill: #c62828; }
.stroke-red-800 { stroke: #c62828; }
.color-red-900 { color: #b71c1c; }
.bg-red-900 { background-color: #b71c1c; }
.border-red-900 { border-color: #b71c1c; }
.fill-red-900 { fill: #b71c1c; }
.stroke-red-900 { stroke: #b71c1c; }
.color-red-a100 { color: #ff8a80; }
.bg-red-a100 { background-color: #ff8a80; }
.border-red-a100 { border-color: #ff8a80; }
.fill-red-a100 { fill: #ff8a80; }
.stroke-red-a100 { stroke: #ff8a80; }
.color-red-a200 { color: #ff5252; }
.bg-red-a200 { background-color: #ff5252; }
.border-red-a200 { border-color: #ff5252; }
.fill-red-a200 { fill: #ff5252; }
.stroke-red-a200 { stroke: #ff5252; }
.color-red-a400 { color: #ff1744; }
.bg-red-a400 { background-color: #ff1744; }
.border-red-a400 { border-color: #ff1744; }
.fill-red-a400 { fill: #ff1744; }
.stroke-red-a400 { stroke: #ff1744; }
.color-red-a700 { color: #d50000; }
.bg-red-a700 { background-color: #d50000; }
.border-red-a700 { border-color: #d50000; }
.fill-red-a700 { fill: #d50000; }
.stroke-red-a700 { stroke: #d50000; }


.color-pink-50 { color: #fce4ec; }
.bg-pink-50 { background-color: #fce4ec; }
.border-pink-50 { border-color: #fce4ec; }
.fill-pink-50 { fill: #fce4ec; }
.stroke-pink-50 { stroke: #fce4ec; }
.color-pink-100 { color: #f8bbd0; }
.bg-pink-100 { background-color: #f8bbd0; }
.border-pink-100 { border-color: #f8bbd0; }
.fill-pink-100 { fill: #f8bbd0; }
.stroke-pink-100 { stroke: #f8bbd0; }
.color-pink-200 { color: #f48fb1; }
.bg-pink-200 { background-color: #f48fb1; }
.border-pink-200 { border-color: #f48fb1; }
.fill-pink-200 { fill: #f48fb1; }
.stroke-pink-200 { stroke: #f48fb1; }
.color-pink-300 { color: #f06292; }
.bg-pink-300 { background-color: #f06292; }
.border-pink-300 { border-color: #f06292; }
.fill-pink-300 { fill: #f06292; }
.stroke-pink-300 { stroke: #f06292; }
.color-pink-400 { color: #ec407a; }
.bg-pink-400 { background-color: #ec407a; }
.border-pink-400 { border-color: #ec407a; }
.fill-pink-400 { fill: #ec407a; }
.stroke-pink-400 { stroke: #ec407a; }
.color-pink-500 { color: #e91e63; }
.bg-pink-500 { background-color: #e91e63; }
.border-pink-500 { border-color: #e91e63; }
.fill-pink-500 { fill: #e91e63; }
.stroke-pink-500 { stroke: #e91e63; }
.color-pink-600 { color: #d81b60; }
.bg-pink-600 { background-color: #d81b60; }
.border-pink-600 { border-color: #d81b60; }
.fill-pink-600 { fill: #d81b60; }
.stroke-pink-600 { stroke: #d81b60; }
.color-pink-700 { color: #c2185b; }
.bg-pink-700 { background-color: #c2185b; }
.border-pink-700 { border-color: #c2185b; }
.fill-pink-700 { fill: #c2185b; }
.stroke-pink-700 { stroke: #c2185b; }
.color-pink-800 { color: #ad1457; }
.bg-pink-800 { background-color: #ad1457; }
.border-pink-800 { border-color: #ad1457; }
.fill-pink-800 { fill: #ad1457; }
.stroke-pink-800 { stroke: #ad1457; }
.color-pink-900 { color: #880e4f; }
.bg-pink-900 { background-color: #880e4f; }
.border-pink-900 { border-color: #880e4f; }
.fill-pink-900 { fill: #880e4f; }
.stroke-pink-900 { stroke: #880e4f; }
.color-pink-a100 { color: #ff80ab; }
.bg-pink-a100 { background-color: #ff80ab; }
.border-pink-a100 { border-color: #ff80ab; }
.fill-pink-a100 { fill: #ff80ab; }
.stroke-pink-a100 { stroke: #ff80ab; }
.color-pink-a200 { color: #ff4081; }
.bg-pink-a200 { background-color: #ff4081; }
.border-pink-a200 { border-color: #ff4081; }
.fill-pink-a200 { fill: #ff4081; }
.stroke-pink-a200 { stroke: #ff4081; }
.color-pink-a400 { color: #f50057; }
.bg-pink-a400 { background-color: #f50057; }
.border-pink-a400 { border-color: #f50057; }
.fill-pink-a400 { fill: #f50057; }
.stroke-pink-a400 { stroke: #f50057; }
.color-pink-a700 { color: #c51162; }
.bg-pink-a700 { background-color: #c51162; }
.border-pink-a700 { border-color: #c51162; }
.fill-pink-a700 { fill: #c51162; }
.stroke-pink-a700 { stroke: #c51162; }


.color-purple-50 { color: #f3e5f5; }
.bg-purple-50 { background-color: #f3e5f5; }
.border-purple-50 { border-color: #f3e5f5; }
.fill-purple-50 { fill: #f3e5f5; }
.stroke-purple-50 { stroke: #f3e5f5; }
.color-purple-100 { color: #e1bee7; }
.bg-purple-100 { background-color: #e1bee7; }
.border-purple-100 { border-color: #e1bee7; }
.fill-purple-100 { fill: #e1bee7; }
.stroke-purple-100 { stroke: #e1bee7; }
.color-purple-200 { color: #ce93d8; }
.bg-purple-200 { background-color: #ce93d8; }
.border-purple-200 { border-color: #ce93d8; }
.fill-purple-200 { fill: #ce93d8; }
.stroke-purple-200 { stroke: #ce93d8; }
.color-purple-300 { color: #ba68c8; }
.bg-purple-300 { background-color: #ba68c8; }
.border-purple-300 { border-color: #ba68c8; }
.fill-purple-300 { fill: #ba68c8; }
.stroke-purple-300 { stroke: #ba68c8; }
.color-purple-400 { color: #ab47bc; }
.bg-purple-400 { background-color: #ab47bc; }
.border-purple-400 { border-color: #ab47bc; }
.fill-purple-400 { fill: #ab47bc; }
.stroke-purple-400 { stroke: #ab47bc; }
.color-purple-500 { color: #9c27b0; }
.bg-purple-500 { background-color: #9c27b0; }
.border-purple-500 { border-color: #9c27b0; }
.fill-purple-500 { fill: #9c27b0; }
.stroke-purple-500 { stroke: #9c27b0; }
.color-purple-600 { color: #8e24aa; }
.bg-purple-600 { background-color: #8e24aa; }
.border-purple-600 { border-color: #8e24aa; }
.fill-purple-600 { fill: #8e24aa; }
.stroke-purple-600 { stroke: #8e24aa; }
.color-purple-700 { color: #7b1fa2; }
.bg-purple-700 { background-color: #7b1fa2; }
.border-purple-700 { border-color: #7b1fa2; }
.fill-purple-700 { fill: #7b1fa2; }
.stroke-purple-700 { stroke: #7b1fa2; }
.color-purple-800 { color: #6a1b9a; }
.bg-purple-800 { background-color: #6a1b9a; }
.border-purple-800 { border-color: #6a1b9a; }
.fill-purple-800 { fill: #6a1b9a; }
.stroke-purple-800 { stroke: #6a1b9a; }
.color-purple-900 { color: #4a148c; }
.bg-purple-900 { background-color: #4a148c; }
.border-purple-900 { border-color: #4a148c; }
.fill-purple-900 { fill: #4a148c; }
.stroke-purple-900 { stroke: #4a148c; }
.color-purple-a100 { color: #ea80fc; }
.bg-purple-a100 { background-color: #ea80fc; }
.border-purple-a100 { border-color: #ea80fc; }
.fill-purple-a100 { fill: #ea80fc; }
.stroke-purple-a100 { stroke: #ea80fc; }
.color-purple-a200 { color: #e040fb; }
.bg-purple-a200 { background-color: #e040fb; }
.border-purple-a200 { border-color: #e040fb; }
.fill-purple-a200 { fill: #e040fb; }
.stroke-purple-a200 { stroke: #e040fb; }
.color-purple-a400 { color: #d500f9; }
.bg-purple-a400 { background-color: #d500f9; }
.border-purple-a400 { border-color: #d500f9; }
.fill-purple-a400 { fill: #d500f9; }
.stroke-purple-a400 { stroke: #d500f9; }
.color-purple-a700 { color: #aa00ff; }
.bg-purple-a700 { background-color: #aa00ff; }
.border-purple-a700 { border-color: #aa00ff; }
.fill-purple-a700 { fill: #aa00ff; }
.stroke-purple-a700 { stroke: #aa00ff; }


.color-deep-purple-50 { color: #ede7f6; }
.bg-deep-purple-50 { background-color: #ede7f6; }
.border-deep-purple-50 { border-color: #ede7f6; }
.fill-deep-purple-50 { fill: #ede7f6; }
.stroke-deep-purple-50 { stroke: #ede7f6; }
.color-deep-purple-100 { color: #d1c4e9; }
.bg-deep-purple-100 { background-color: #d1c4e9; }
.border-deep-purple-100 { border-color: #d1c4e9; }
.fill-deep-purple-100 { fill: #d1c4e9; }
.stroke-deep-purple-100 { stroke: #d1c4e9; }
.color-deep-purple-200 { color: #b39ddb; }
.bg-deep-purple-200 { background-color: #b39ddb; }
.border-deep-purple-200 { border-color: #b39ddb; }
.fill-deep-purple-200 { fill: #b39ddb; }
.stroke-deep-purple-200 { stroke: #b39ddb; }
.color-deep-purple-300 { color: #9575cd; }
.bg-deep-purple-300 { background-color: #9575cd; }
.border-deep-purple-300 { border-color: #9575cd; }
.fill-deep-purple-300 { fill: #9575cd; }
.stroke-deep-purple-300 { stroke: #9575cd; }
.color-deep-purple-400 { color: #7e57c2; }
.bg-deep-purple-400 { background-color: #7e57c2; }
.border-deep-purple-400 { border-color: #7e57c2; }
.fill-deep-purple-400 { fill: #7e57c2; }
.stroke-deep-purple-400 { stroke: #7e57c2; }
.color-deep-purple-500 { color: #673ab7; }
.bg-deep-purple-500 { background-color: #673ab7; }
.border-deep-purple-500 { border-color: #673ab7; }
.fill-deep-purple-500 { fill: #673ab7; }
.stroke-deep-purple-500 { stroke: #673ab7; }
.color-deep-purple-600 { color: #5e35b1; }
.bg-deep-purple-600 { background-color: #5e35b1; }
.border-deep-purple-600 { border-color: #5e35b1; }
.fill-deep-purple-600 { fill: #5e35b1; }
.stroke-deep-purple-600 { stroke: #5e35b1; }
.color-deep-purple-700 { color: #512da8; }
.bg-deep-purple-700 { background-color: #512da8; }
.border-deep-purple-700 { border-color: #512da8; }
.fill-deep-purple-700 { fill: #512da8; }
.stroke-deep-purple-700 { stroke: #512da8; }
.color-deep-purple-800 { color: #4527a0; }
.bg-deep-purple-800 { background-color: #4527a0; }
.border-deep-purple-800 { border-color: #4527a0; }
.fill-deep-purple-800 { fill: #4527a0; }
.stroke-deep-purple-800 { stroke: #4527a0; }
.color-deep-purple-900 { color: #311b92; }
.bg-deep-purple-900 { background-color: #311b92; }
.border-deep-purple-900 { border-color: #311b92; }
.fill-deep-purple-900 { fill: #311b92; }
.stroke-deep-purple-900 { stroke: #311b92; }
.color-deep-purple-a100 { color: #b388ff; }
.bg-deep-purple-a100 { background-color: #b388ff; }
.border-deep-purple-a100 { border-color: #b388ff; }
.fill-deep-purple-a100 { fill: #b388ff; }
.stroke-deep-purple-a100 { stroke: #b388ff; }
.color-deep-purple-a200 { color: #7c4dff; }
.bg-deep-purple-a200 { background-color: #7c4dff; }
.border-deep-purple-a200 { border-color: #7c4dff; }
.fill-deep-purple-a200 { fill: #7c4dff; }
.stroke-deep-purple-a200 { stroke: #7c4dff; }
.color-deep-purple-a400 { color: #651fff; }
.bg-deep-purple-a400 { background-color: #651fff; }
.border-deep-purple-a400 { border-color: #651fff; }
.fill-deep-purple-a400 { fill: #651fff; }
.stroke-deep-purple-a400 { stroke: #651fff; }
.color-deep-purple-a700 { color: #6200ea; }
.bg-deep-purple-a700 { background-color: #6200ea; }
.border-deep-purple-a700 { border-color: #6200ea; }
.fill-deep-purple-a700 { fill: #6200ea; }
.stroke-deep-purple-a700 { stroke: #6200ea; }


.color-indigo-50 { color: #e8eaf6; }
.bg-indigo-50 { background-color: #e8eaf6; }
.border-indigo-50 { border-color: #e8eaf6; }
.fill-indigo-50 { fill: #e8eaf6; }
.stroke-indigo-50 { stroke: #e8eaf6; }
.color-indigo-100 { color: #c5cae9; }
.bg-indigo-100 { background-color: #c5cae9; }
.border-indigo-100 { border-color: #c5cae9; }
.fill-indigo-100 { fill: #c5cae9; }
.stroke-indigo-100 { stroke: #c5cae9; }
.color-indigo-200 { color: #9fa8da; }
.bg-indigo-200 { background-color: #9fa8da; }
.border-indigo-200 { border-color: #9fa8da; }
.fill-indigo-200 { fill: #9fa8da; }
.stroke-indigo-200 { stroke: #9fa8da; }
.color-indigo-300 { color: #7986cb; }
.bg-indigo-300 { background-color: #7986cb; }
.border-indigo-300 { border-color: #7986cb; }
.fill-indigo-300 { fill: #7986cb; }
.stroke-indigo-300 { stroke: #7986cb; }
.color-indigo-400 { color: #5c6bc0; }
.bg-indigo-400 { background-color: #5c6bc0; }
.border-indigo-400 { border-color: #5c6bc0; }
.fill-indigo-400 { fill: #5c6bc0; }
.stroke-indigo-400 { stroke: #5c6bc0; }
.color-indigo-500 { color: #3f51b5; }
.bg-indigo-500 { background-color: #3f51b5; }
.border-indigo-500 { border-color: #3f51b5; }
.fill-indigo-500 { fill: #3f51b5; }
.stroke-indigo-500 { stroke: #3f51b5; }
.color-indigo-600 { color: #3949ab; }
.bg-indigo-600 { background-color: #3949ab; }
.border-indigo-600 { border-color: #3949ab; }
.fill-indigo-600 { fill: #3949ab; }
.stroke-indigo-600 { stroke: #3949ab; }
.color-indigo-700 { color: #303f9f; }
.bg-indigo-700 { background-color: #303f9f; }
.border-indigo-700 { border-color: #303f9f; }
.fill-indigo-700 { fill: #303f9f; }
.stroke-indigo-700 { stroke: #303f9f; }
.color-indigo-800 { color: #283593; }
.bg-indigo-800 { background-color: #283593; }
.border-indigo-800 { border-color: #283593; }
.fill-indigo-800 { fill: #283593; }
.stroke-indigo-800 { stroke: #283593; }
.color-indigo-900 { color: #1a237e; }
.bg-indigo-900 { background-color: #1a237e; }
.border-indigo-900 { border-color: #1a237e; }
.fill-indigo-900 { fill: #1a237e; }
.stroke-indigo-900 { stroke: #1a237e; }
.color-indigo-a100 { color: #8c9eff; }
.bg-indigo-a100 { background-color: #8c9eff; }
.border-indigo-a100 { border-color: #8c9eff; }
.fill-indigo-a100 { fill: #8c9eff; }
.stroke-indigo-a100 { stroke: #8c9eff; }
.color-indigo-a200 { color: #536dfe; }
.bg-indigo-a200 { background-color: #536dfe; }
.border-indigo-a200 { border-color: #536dfe; }
.fill-indigo-a200 { fill: #536dfe; }
.stroke-indigo-a200 { stroke: #536dfe; }
.color-indigo-a400 { color: #3d5afe; }
.bg-indigo-a400 { background-color: #3d5afe; }
.border-indigo-a400 { border-color: #3d5afe; }
.fill-indigo-a400 { fill: #3d5afe; }
.stroke-indigo-a400 { stroke: #3d5afe; }
.color-indigo-a700 { color: #304ffe; }
.bg-indigo-a700 { background-color: #304ffe; }
.border-indigo-a700 { border-color: #304ffe; }
.fill-indigo-a700 { fill: #304ffe; }
.stroke-indigo-a700 { stroke: #304ffe; }


.color-blue-50 { color: #e3f2fd; }
.bg-blue-50 { background-color: #e3f2fd; }
.border-blue-50 { border-color: #e3f2fd; }
.fill-blue-50 { fill: #e3f2fd; }
.stroke-blue-50 { stroke: #e3f2fd; }
.color-blue-100 { color: #bbdefb; }
.bg-blue-100 { background-color: #bbdefb; }
.border-blue-100 { border-color: #bbdefb; }
.fill-blue-100 { fill: #bbdefb; }
.stroke-blue-100 { stroke: #bbdefb; }
.color-blue-200 { color: #90caf9; }
.bg-blue-200 { background-color: #90caf9; }
.border-blue-200 { border-color: #90caf9; }
.fill-blue-200 { fill: #90caf9; }
.stroke-blue-200 { stroke: #90caf9; }
.color-blue-300 { color: #64b5f6; }
.bg-blue-300 { background-color: #64b5f6; }
.border-blue-300 { border-color: #64b5f6; }
.fill-blue-300 { fill: #64b5f6; }
.stroke-blue-300 { stroke: #64b5f6; }
.color-blue-400 { color: #42a5f5; }
.bg-blue-400 { background-color: #42a5f5; }
.border-blue-400 { border-color: #42a5f5; }
.fill-blue-400 { fill: #42a5f5; }
.stroke-blue-400 { stroke: #42a5f5; }
.color-blue-500 { color: #2196f3; }
.bg-blue-500 { background-color: #2196f3; }
.border-blue-500 { border-color: #2196f3; }
.fill-blue-500 { fill: #2196f3; }
.stroke-blue-500 { stroke: #2196f3; }
.color-blue-600 { color: #1e88e5; }
.bg-blue-600 { background-color: #1e88e5; }
.border-blue-600 { border-color: #1e88e5; }
.fill-blue-600 { fill: #1e88e5; }
.stroke-blue-600 { stroke: #1e88e5; }
.color-blue-700 { color: #1976d2; }
.bg-blue-700 { background-color: #1976d2; }
.border-blue-700 { border-color: #1976d2; }
.fill-blue-700 { fill: #1976d2; }
.stroke-blue-700 { stroke: #1976d2; }
.color-blue-800 { color: #1565c0; }
.bg-blue-800 { background-color: #1565c0; }
.border-blue-800 { border-color: #1565c0; }
.fill-blue-800 { fill: #1565c0; }
.stroke-blue-800 { stroke: #1565c0; }
.color-blue-900 { color: #0d47a1; }
.bg-blue-900 { background-color: #0d47a1; }
.border-blue-900 { border-color: #0d47a1; }
.fill-blue-900 { fill: #0d47a1; }
.stroke-blue-900 { stroke: #0d47a1; }
.color-blue-a100 { color: #82b1ff; }
.bg-blue-a100 { background-color: #82b1ff; }
.border-blue-a100 { border-color: #82b1ff; }
.fill-blue-a100 { fill: #82b1ff; }
.stroke-blue-a100 { stroke: #82b1ff; }
.color-blue-a200 { color: #448aff; }
.bg-blue-a200 { background-color: #448aff; }
.border-blue-a200 { border-color: #448aff; }
.fill-blue-a200 { fill: #448aff; }
.stroke-blue-a200 { stroke: #448aff; }
.color-blue-a400 { color: #2979ff; }
.bg-blue-a400 { background-color: #2979ff; }
.border-blue-a400 { border-color: #2979ff; }
.fill-blue-a400 { fill: #2979ff; }
.stroke-blue-a400 { stroke: #2979ff; }
.color-blue-a700 { color: #2962ff; }
.bg-blue-a700 { background-color: #2962ff; }
.border-blue-a700 { border-color: #2962ff; }
.fill-blue-a700 { fill: #2962ff; }
.stroke-blue-a700 { stroke: #2962ff; }


.color-light-blue-50 { color: #e1f5fe; }
.bg-light-blue-50 { background-color: #e1f5fe; }
.border-light-blue-50 { border-color: #e1f5fe; }
.fill-light-blue-50 { fill: #e1f5fe; }
.stroke-light-blue-50 { stroke: #e1f5fe; }
.color-light-blue-100 { color: #b3e5fc; }
.bg-light-blue-100 { background-color: #b3e5fc; }
.border-light-blue-100 { border-color: #b3e5fc; }
.fill-light-blue-100 { fill: #b3e5fc; }
.stroke-light-blue-100 { stroke: #b3e5fc; }
.color-light-blue-200 { color: #81d4fa; }
.bg-light-blue-200 { background-color: #81d4fa; }
.border-light-blue-200 { border-color: #81d4fa; }
.fill-light-blue-200 { fill: #81d4fa; }
.stroke-light-blue-200 { stroke: #81d4fa; }
.color-light-blue-300 { color: #4fc3f7; }
.bg-light-blue-300 { background-color: #4fc3f7; }
.border-light-blue-300 { border-color: #4fc3f7; }
.fill-light-blue-300 { fill: #4fc3f7; }
.stroke-light-blue-300 { stroke: #4fc3f7; }
.color-light-blue-400 { color: #29b6f6; }
.bg-light-blue-400 { background-color: #29b6f6; }
.border-light-blue-400 { border-color: #29b6f6; }
.fill-light-blue-400 { fill: #29b6f6; }
.stroke-light-blue-400 { stroke: #29b6f6; }
.color-light-blue-500 { color: #03a9f4; }
.bg-light-blue-500 { background-color: #03a9f4; }
.border-light-blue-500 { border-color: #03a9f4; }
.fill-light-blue-500 { fill: #03a9f4; }
.stroke-light-blue-500 { stroke: #03a9f4; }
.color-light-blue-600 { color: #039be5; }
.bg-light-blue-600 { background-color: #039be5; }
.border-light-blue-600 { border-color: #039be5; }
.fill-light-blue-600 { fill: #039be5; }
.stroke-light-blue-600 { stroke: #039be5; }
.color-light-blue-700 { color: #0288d1; }
.bg-light-blue-700 { background-color: #0288d1; }
.border-light-blue-700 { border-color: #0288d1; }
.fill-light-blue-700 { fill: #0288d1; }
.stroke-light-blue-700 { stroke: #0288d1; }
.color-light-blue-800 { color: #0277bd; }
.bg-light-blue-800 { background-color: #0277bd; }
.border-light-blue-800 { border-color: #0277bd; }
.fill-light-blue-800 { fill: #0277bd; }
.stroke-light-blue-800 { stroke: #0277bd; }
.color-light-blue-900 { color: #01579b; }
.bg-light-blue-900 { background-color: #01579b; }
.border-light-blue-900 { border-color: #01579b; }
.fill-light-blue-900 { fill: #01579b; }
.stroke-light-blue-900 { stroke: #01579b; }
.color-light-blue-a100 { color: #80d8ff; }
.bg-light-blue-a100 { background-color: #80d8ff; }
.border-light-blue-a100 { border-color: #80d8ff; }
.fill-light-blue-a100 { fill: #80d8ff; }
.stroke-light-blue-a100 { stroke: #80d8ff; }
.color-light-blue-a200 { color: #40c4ff; }
.bg-light-blue-a200 { background-color: #40c4ff; }
.border-light-blue-a200 { border-color: #40c4ff; }
.fill-light-blue-a200 { fill: #40c4ff; }
.stroke-light-blue-a200 { stroke: #40c4ff; }
.color-light-blue-a400 { color: #00b0ff; }
.bg-light-blue-a400 { background-color: #00b0ff; }
.border-light-blue-a400 { border-color: #00b0ff; }
.fill-light-blue-a400 { fill: #00b0ff; }
.stroke-light-blue-a400 { stroke: #00b0ff; }
.color-light-blue-a700 { color: #0091ea; }
.bg-light-blue-a700 { background-color: #0091ea; }
.border-light-blue-a700 { border-color: #0091ea; }
.fill-light-blue-a700 { fill: #0091ea; }
.stroke-light-blue-a700 { stroke: #0091ea; }


.color-cyan-50 { color: #e0f7fa; }
.bg-cyan-50 { background-color: #e0f7fa; }
.border-cyan-50 { border-color: #e0f7fa; }
.fill-cyan-50 { fill: #e0f7fa; }
.stroke-cyan-50 { stroke: #e0f7fa; }
.color-cyan-100 { color: #b2ebf2; }
.bg-cyan-100 { background-color: #b2ebf2; }
.border-cyan-100 { border-color: #b2ebf2; }
.fill-cyan-100 { fill: #b2ebf2; }
.stroke-cyan-100 { stroke: #b2ebf2; }
.color-cyan-200 { color: #80deea; }
.bg-cyan-200 { background-color: #80deea; }
.border-cyan-200 { border-color: #80deea; }
.fill-cyan-200 { fill: #80deea; }
.stroke-cyan-200 { stroke: #80deea; }
.color-cyan-300 { color: #4dd0e1; }
.bg-cyan-300 { background-color: #4dd0e1; }
.border-cyan-300 { border-color: #4dd0e1; }
.fill-cyan-300 { fill: #4dd0e1; }
.stroke-cyan-300 { stroke: #4dd0e1; }
.color-cyan-400 { color: #26c6da; }
.bg-cyan-400 { background-color: #26c6da; }
.border-cyan-400 { border-color: #26c6da; }
.fill-cyan-400 { fill: #26c6da; }
.stroke-cyan-400 { stroke: #26c6da; }
.color-cyan-500 { color: #00bcd4; }
.bg-cyan-500 { background-color: #00bcd4; }
.border-cyan-500 { border-color: #00bcd4; }
.fill-cyan-500 { fill: #00bcd4; }
.stroke-cyan-500 { stroke: #00bcd4; }
.color-cyan-600 { color: #00acc1; }
.bg-cyan-600 { background-color: #00acc1; }
.border-cyan-600 { border-color: #00acc1; }
.fill-cyan-600 { fill: #00acc1; }
.stroke-cyan-600 { stroke: #00acc1; }
.color-cyan-700 { color: #0097a7; }
.bg-cyan-700 { background-color: #0097a7; }
.border-cyan-700 { border-color: #0097a7; }
.fill-cyan-700 { fill: #0097a7; }
.stroke-cyan-700 { stroke: #0097a7; }
.color-cyan-800 { color: #00838f; }
.bg-cyan-800 { background-color: #00838f; }
.border-cyan-800 { border-color: #00838f; }
.fill-cyan-800 { fill: #00838f; }
.stroke-cyan-800 { stroke: #00838f; }
.color-cyan-900 { color: #006064; }
.bg-cyan-900 { background-color: #006064; }
.border-cyan-900 { border-color: #006064; }
.fill-cyan-900 { fill: #006064; }
.stroke-cyan-900 { stroke: #006064; }
.color-cyan-a100 { color: #84ffff; }
.bg-cyan-a100 { background-color: #84ffff; }
.border-cyan-a100 { border-color: #84ffff; }
.fill-cyan-a100 { fill: #84ffff; }
.stroke-cyan-a100 { stroke: #84ffff; }
.color-cyan-a200 { color: #18ffff; }
.bg-cyan-a200 { background-color: #18ffff; }
.border-cyan-a200 { border-color: #18ffff; }
.fill-cyan-a200 { fill: #18ffff; }
.stroke-cyan-a200 { stroke: #18ffff; }
.color-cyan-a400 { color: #00e5ff; }
.bg-cyan-a400 { background-color: #00e5ff; }
.border-cyan-a400 { border-color: #00e5ff; }
.fill-cyan-a400 { fill: #00e5ff; }
.stroke-cyan-a400 { stroke: #00e5ff; }
.color-cyan-a700 { color: #00b8d4; }
.bg-cyan-a700 { background-color: #00b8d4; }
.border-cyan-a700 { border-color: #00b8d4; }
.fill-cyan-a700 { fill: #00b8d4; }
.stroke-cyan-a700 { stroke: #00b8d4; }


.color-teal-50 { color: #e0f2f1; }
.bg-teal-50 { background-color: #e0f2f1; }
.border-teal-50 { border-color: #e0f2f1; }
.fill-teal-50 { fill: #e0f2f1; }
.stroke-teal-50 { stroke: #e0f2f1; }
.color-teal-100 { color: #b2dfdb; }
.bg-teal-100 { background-color: #b2dfdb; }
.border-teal-100 { border-color: #b2dfdb; }
.fill-teal-100 { fill: #b2dfdb; }
.stroke-teal-100 { stroke: #b2dfdb; }
.color-teal-200 { color: #80cbc4; }
.bg-teal-200 { background-color: #80cbc4; }
.border-teal-200 { border-color: #80cbc4; }
.fill-teal-200 { fill: #80cbc4; }
.stroke-teal-200 { stroke: #80cbc4; }
.color-teal-300 { color: #4db6ac; }
.bg-teal-300 { background-color: #4db6ac; }
.border-teal-300 { border-color: #4db6ac; }
.fill-teal-300 { fill: #4db6ac; }
.stroke-teal-300 { stroke: #4db6ac; }
.color-teal-400 { color: #26a69a; }
.bg-teal-400 { background-color: #26a69a; }
.border-teal-400 { border-color: #26a69a; }
.fill-teal-400 { fill: #26a69a; }
.stroke-teal-400 { stroke: #26a69a; }
.color-teal-500 { color: #009688; }
.bg-teal-500 { background-color: #009688; }
.border-teal-500 { border-color: #009688; }
.fill-teal-500 { fill: #009688; }
.stroke-teal-500 { stroke: #009688; }
.color-teal-600 { color: #00897b; }
.bg-teal-600 { background-color: #00897b; }
.border-teal-600 { border-color: #00897b; }
.fill-teal-600 { fill: #00897b; }
.stroke-teal-600 { stroke: #00897b; }
.color-teal-700 { color: #00796b; }
.bg-teal-700 { background-color: #00796b; }
.border-teal-700 { border-color: #00796b; }
.fill-teal-700 { fill: #00796b; }
.stroke-teal-700 { stroke: #00796b; }
.color-teal-800 { color: #00695c; }
.bg-teal-800 { background-color: #00695c; }
.border-teal-800 { border-color: #00695c; }
.fill-teal-800 { fill: #00695c; }
.stroke-teal-800 { stroke: #00695c; }
.color-teal-900 { color: #004d40; }
.bg-teal-900 { background-color: #004d40; }
.border-teal-900 { border-color: #004d40; }
.fill-teal-900 { fill: #004d40; }
.stroke-teal-900 { stroke: #004d40; }
.color-teal-a100 { color: #a7ffeb; }
.bg-teal-a100 { background-color: #a7ffeb; }
.border-teal-a100 { border-color: #a7ffeb; }
.fill-teal-a100 { fill: #a7ffeb; }
.stroke-teal-a100 { stroke: #a7ffeb; }
.color-teal-a200 { color: #64ffda; }
.bg-teal-a200 { background-color: #64ffda; }
.border-teal-a200 { border-color: #64ffda; }
.fill-teal-a200 { fill: #64ffda; }
.stroke-teal-a200 { stroke: #64ffda; }
.color-teal-a400 { color: #1de9b6; }
.bg-teal-a400 { background-color: #1de9b6; }
.border-teal-a400 { border-color: #1de9b6; }
.fill-teal-a400 { fill: #1de9b6; }
.stroke-teal-a400 { stroke: #1de9b6; }
.color-teal-a700 { color: #00bfa5; }
.bg-teal-a700 { background-color: #00bfa5; }
.border-teal-a700 { border-color: #00bfa5; }
.fill-teal-a700 { fill: #00bfa5; }
.stroke-teal-a700 { stroke: #00bfa5; }


.color-green-50 { color: #e8f5e9; }
.bg-green-50 { background-color: #e8f5e9; }
.border-green-50 { border-color: #e8f5e9; }
.fill-green-50 { fill: #e8f5e9; }
.stroke-green-50 { stroke: #e8f5e9; }
.color-green-100 { color: #c8e6c9; }
.bg-green-100 { background-color: #c8e6c9; }
.border-green-100 { border-color: #c8e6c9; }
.fill-green-100 { fill: #c8e6c9; }
.stroke-green-100 { stroke: #c8e6c9; }
.color-green-200 { color: #a5d6a7; }
.bg-green-200 { background-color: #a5d6a7; }
.border-green-200 { border-color: #a5d6a7; }
.fill-green-200 { fill: #a5d6a7; }
.stroke-green-200 { stroke: #a5d6a7; }
.color-green-300 { color: #81c784; }
.bg-green-300 { background-color: #81c784; }
.border-green-300 { border-color: #81c784; }
.fill-green-300 { fill: #81c784; }
.stroke-green-300 { stroke: #81c784; }
.color-green-400 { color: #66bb6a; }
.bg-green-400 { background-color: #66bb6a; }
.border-green-400 { border-color: #66bb6a; }
.fill-green-400 { fill: #66bb6a; }
.stroke-green-400 { stroke: #66bb6a; }
.color-green-500 { color: #4caf50; }
.bg-green-500 { background-color: #4caf50; }
.border-green-500 { border-color: #4caf50; }
.fill-green-500 { fill: #4caf50; }
.stroke-green-500 { stroke: #4caf50; }
.color-green-600 { color: #43a047; }
.bg-green-600 { background-color: #43a047; }
.border-green-600 { border-color: #43a047; }
.fill-green-600 { fill: #43a047; }
.stroke-green-600 { stroke: #43a047; }
.color-green-700 { color: #388e3c; }
.bg-green-700 { background-color: #388e3c; }
.border-green-700 { border-color: #388e3c; }
.fill-green-700 { fill: #388e3c; }
.stroke-green-700 { stroke: #388e3c; }
.color-green-800 { color: #2e7d32; }
.bg-green-800 { background-color: #2e7d32; }
.border-green-800 { border-color: #2e7d32; }
.fill-green-800 { fill: #2e7d32; }
.stroke-green-800 { stroke: #2e7d32; }
.color-green-900 { color: #1b5e20; }
.bg-green-900 { background-color: #1b5e20; }
.border-green-900 { border-color: #1b5e20; }
.fill-green-900 { fill: #1b5e20; }
.stroke-green-900 { stroke: #1b5e20; }
.color-green-a100 { color: #b9f6ca; }
.bg-green-a100 { background-color: #b9f6ca; }
.border-green-a100 { border-color: #b9f6ca; }
.fill-green-a100 { fill: #b9f6ca; }
.stroke-green-a100 { stroke: #b9f6ca; }
.color-green-a200 { color: #69f0ae; }
.bg-green-a200 { background-color: #69f0ae; }
.border-green-a200 { border-color: #69f0ae; }
.fill-green-a200 { fill: #69f0ae; }
.stroke-green-a200 { stroke: #69f0ae; }
.color-green-a400 { color: #00e676; }
.bg-green-a400 { background-color: #00e676; }
.border-green-a400 { border-color: #00e676; }
.fill-green-a400 { fill: #00e676; }
.stroke-green-a400 { stroke: #00e676; }
.color-green-a700 { color: #00c853; }
.bg-green-a700 { background-color: #00c853; }
.border-green-a700 { border-color: #00c853; }
.fill-green-a700 { fill: #00c853; }
.stroke-green-a700 { stroke: #00c853; }


.color-light-green-50 { color: #f1f8e9; }
.bg-light-green-50 { background-color: #f1f8e9; }
.border-light-green-50 { border-color: #f1f8e9; }
.fill-light-green-50 { fill: #f1f8e9; }
.stroke-light-green-50 { stroke: #f1f8e9; }
.color-light-green-100 { color: #dcedc8; }
.bg-light-green-100 { background-color: #dcedc8; }
.border-light-green-100 { border-color: #dcedc8; }
.fill-light-green-100 { fill: #dcedc8; }
.stroke-light-green-100 { stroke: #dcedc8; }
.color-light-green-200 { color: #c5e1a5; }
.bg-light-green-200 { background-color: #c5e1a5; }
.border-light-green-200 { border-color: #c5e1a5; }
.fill-light-green-200 { fill: #c5e1a5; }
.stroke-light-green-200 { stroke: #c5e1a5; }
.color-light-green-300 { color: #aed581; }
.bg-light-green-300 { background-color: #aed581; }
.border-light-green-300 { border-color: #aed581; }
.fill-light-green-300 { fill: #aed581; }
.stroke-light-green-300 { stroke: #aed581; }
.color-light-green-400 { color: #9ccc65; }
.bg-light-green-400 { background-color: #9ccc65; }
.border-light-green-400 { border-color: #9ccc65; }
.fill-light-green-400 { fill: #9ccc65; }
.stroke-light-green-400 { stroke: #9ccc65; }
.color-light-green-500 { color: #8bc34a; }
.bg-light-green-500 { background-color: #8bc34a; }
.border-light-green-500 { border-color: #8bc34a; }
.fill-light-green-500 { fill: #8bc34a; }
.stroke-light-green-500 { stroke: #8bc34a; }
.color-light-green-600 { color: #7cb342; }
.bg-light-green-600 { background-color: #7cb342; }
.border-light-green-600 { border-color: #7cb342; }
.fill-light-green-600 { fill: #7cb342; }
.stroke-light-green-600 { stroke: #7cb342; }
.color-light-green-700 { color: #689f38; }
.bg-light-green-700 { background-color: #689f38; }
.border-light-green-700 { border-color: #689f38; }
.fill-light-green-700 { fill: #689f38; }
.stroke-light-green-700 { stroke: #689f38; }
.color-light-green-800 { color: #558b2f; }
.bg-light-green-800 { background-color: #558b2f; }
.border-light-green-800 { border-color: #558b2f; }
.fill-light-green-800 { fill: #558b2f; }
.stroke-light-green-800 { stroke: #558b2f; }
.color-light-green-900 { color: #33691e; }
.bg-light-green-900 { background-color: #33691e; }
.border-light-green-900 { border-color: #33691e; }
.fill-light-green-900 { fill: #33691e; }
.stroke-light-green-900 { stroke: #33691e; }
.color-light-green-a100 { color: #ccff90; }
.bg-light-green-a100 { background-color: #ccff90; }
.border-light-green-a100 { border-color: #ccff90; }
.fill-light-green-a100 { fill: #ccff90; }
.stroke-light-green-a100 { stroke: #ccff90; }
.color-light-green-a200 { color: #b2ff59; }
.bg-light-green-a200 { background-color: #b2ff59; }
.border-light-green-a200 { border-color: #b2ff59; }
.fill-light-green-a200 { fill: #b2ff59; }
.stroke-light-green-a200 { stroke: #b2ff59; }
.color-light-green-a400 { color: #76ff03; }
.bg-light-green-a400 { background-color: #76ff03; }
.border-light-green-a400 { border-color: #76ff03; }
.fill-light-green-a400 { fill: #76ff03; }
.stroke-light-green-a400 { stroke: #76ff03; }
.color-light-green-a700 { color: #64dd17; }
.bg-light-green-a700 { background-color: #64dd17; }
.border-light-green-a700 { border-color: #64dd17; }
.fill-light-green-a700 { fill: #64dd17; }
.stroke-light-green-a700 { stroke: #64dd17; }


.color-lime-50 { color: #f9fbe7; }
.bg-lime-50 { background-color: #f9fbe7; }
.border-lime-50 { border-color: #f9fbe7; }
.fill-lime-50 { fill: #f9fbe7; }
.stroke-lime-50 { stroke: #f9fbe7; }
.color-lime-100 { color: #f0f4c3; }
.bg-lime-100 { background-color: #f0f4c3; }
.border-lime-100 { border-color: #f0f4c3; }
.fill-lime-100 { fill: #f0f4c3; }
.stroke-lime-100 { stroke: #f0f4c3; }
.color-lime-200 { color: #e6ee9c; }
.bg-lime-200 { background-color: #e6ee9c; }
.border-lime-200 { border-color: #e6ee9c; }
.fill-lime-200 { fill: #e6ee9c; }
.stroke-lime-200 { stroke: #e6ee9c; }
.color-lime-300 { color: #dce775; }
.bg-lime-300 { background-color: #dce775; }
.border-lime-300 { border-color: #dce775; }
.fill-lime-300 { fill: #dce775; }
.stroke-lime-300 { stroke: #dce775; }
.color-lime-400 { color: #d4e157; }
.bg-lime-400 { background-color: #d4e157; }
.border-lime-400 { border-color: #d4e157; }
.fill-lime-400 { fill: #d4e157; }
.stroke-lime-400 { stroke: #d4e157; }
.color-lime-500 { color: #cddc39; }
.bg-lime-500 { background-color: #cddc39; }
.border-lime-500 { border-color: #cddc39; }
.fill-lime-500 { fill: #cddc39; }
.stroke-lime-500 { stroke: #cddc39; }
.color-lime-600 { color: #c0ca33; }
.bg-lime-600 { background-color: #c0ca33; }
.border-lime-600 { border-color: #c0ca33; }
.fill-lime-600 { fill: #c0ca33; }
.stroke-lime-600 { stroke: #c0ca33; }
.color-lime-700 { color: #afb42b; }
.bg-lime-700 { background-color: #afb42b; }
.border-lime-700 { border-color: #afb42b; }
.fill-lime-700 { fill: #afb42b; }
.stroke-lime-700 { stroke: #afb42b; }
.color-lime-800 { color: #9e9d24; }
.bg-lime-800 { background-color: #9e9d24; }
.border-lime-800 { border-color: #9e9d24; }
.fill-lime-800 { fill: #9e9d24; }
.stroke-lime-800 { stroke: #9e9d24; }
.color-lime-900 { color: #827717; }
.bg-lime-900 { background-color: #827717; }
.border-lime-900 { border-color: #827717; }
.fill-lime-900 { fill: #827717; }
.stroke-lime-900 { stroke: #827717; }
.color-lime-a100 { color: #f4ff81; }
.bg-lime-a100 { background-color: #f4ff81; }
.border-lime-a100 { border-color: #f4ff81; }
.fill-lime-a100 { fill: #f4ff81; }
.stroke-lime-a100 { stroke: #f4ff81; }
.color-lime-a200 { color: #eeff41; }
.bg-lime-a200 { background-color: #eeff41; }
.border-lime-a200 { border-color: #eeff41; }
.fill-lime-a200 { fill: #eeff41; }
.stroke-lime-a200 { stroke: #eeff41; }
.color-lime-a400 { color: #c6ff00; }
.bg-lime-a400 { background-color: #c6ff00; }
.border-lime-a400 { border-color: #c6ff00; }
.fill-lime-a400 { fill: #c6ff00; }
.stroke-lime-a400 { stroke: #c6ff00; }
.color-lime-a700 { color: #aeea00; }
.bg-lime-a700 { background-color: #aeea00; }
.border-lime-a700 { border-color: #aeea00; }
.fill-lime-a700 { fill: #aeea00; }
.stroke-lime-a700 { stroke: #aeea00; }


.color-yellow-50 { color: #fffde7; }
.bg-yellow-50 { background-color: #fffde7; }
.border-yellow-50 { border-color: #fffde7; }
.fill-yellow-50 { fill: #fffde7; }
.stroke-yellow-50 { stroke: #fffde7; }
.color-yellow-100 { color: #fff9c4; }
.bg-yellow-100 { background-color: #fff9c4; }
.border-yellow-100 { border-color: #fff9c4; }
.fill-yellow-100 { fill: #fff9c4; }
.stroke-yellow-100 { stroke: #fff9c4; }
.color-yellow-200 { color: #fff59d; }
.bg-yellow-200 { background-color: #fff59d; }
.border-yellow-200 { border-color: #fff59d; }
.fill-yellow-200 { fill: #fff59d; }
.stroke-yellow-200 { stroke: #fff59d; }
.color-yellow-300 { color: #fff176; }
.bg-yellow-300 { background-color: #fff176; }
.border-yellow-300 { border-color: #fff176; }
.fill-yellow-300 { fill: #fff176; }
.stroke-yellow-300 { stroke: #fff176; }
.color-yellow-400 { color: #ffee58; }
.bg-yellow-400 { background-color: #ffee58; }
.border-yellow-400 { border-color: #ffee58; }
.fill-yellow-400 { fill: #ffee58; }
.stroke-yellow-400 { stroke: #ffee58; }
.color-yellow-500 { color: #ffeb3b; }
.bg-yellow-500 { background-color: #ffeb3b; }
.border-yellow-500 { border-color: #ffeb3b; }
.fill-yellow-500 { fill: #ffeb3b; }
.stroke-yellow-500 { stroke: #ffeb3b; }
.color-yellow-600 { color: #fdd835; }
.bg-yellow-600 { background-color: #fdd835; }
.border-yellow-600 { border-color: #fdd835; }
.fill-yellow-600 { fill: #fdd835; }
.stroke-yellow-600 { stroke: #fdd835; }
.color-yellow-700 { color: #fbc02d; }
.bg-yellow-700 { background-color: #fbc02d; }
.border-yellow-700 { border-color: #fbc02d; }
.fill-yellow-700 { fill: #fbc02d; }
.stroke-yellow-700 { stroke: #fbc02d; }
.color-yellow-800 { color: #f9a825; }
.bg-yellow-800 { background-color: #f9a825; }
.border-yellow-800 { border-color: #f9a825; }
.fill-yellow-800 { fill: #f9a825; }
.stroke-yellow-800 { stroke: #f9a825; }
.color-yellow-900 { color: #f57f17; }
.bg-yellow-900 { background-color: #f57f17; }
.border-yellow-900 { border-color: #f57f17; }
.fill-yellow-900 { fill: #f57f17; }
.stroke-yellow-900 { stroke: #f57f17; }
.color-yellow-a100 { color: #ffff8d; }
.bg-yellow-a100 { background-color: #ffff8d; }
.border-yellow-a100 { border-color: #ffff8d; }
.fill-yellow-a100 { fill: #ffff8d; }
.stroke-yellow-a100 { stroke: #ffff8d; }
.color-yellow-a200 { color: #ffff00; }
.bg-yellow-a200 { background-color: #ffff00; }
.border-yellow-a200 { border-color: #ffff00; }
.fill-yellow-a200 { fill: #ffff00; }
.stroke-yellow-a200 { stroke: #ffff00; }
.color-yellow-a400 { color: #ffea00; }
.bg-yellow-a400 { background-color: #ffea00; }
.border-yellow-a400 { border-color: #ffea00; }
.fill-yellow-a400 { fill: #ffea00; }
.stroke-yellow-a400 { stroke: #ffea00; }
.color-yellow-a700 { color: #ffd600; }
.bg-yellow-a700 { background-color: #ffd600; }
.border-yellow-a700 { border-color: #ffd600; }
.fill-yellow-a700 { fill: #ffd600; }
.stroke-yellow-a700 { stroke: #ffd600; }


.color-amber-50 { color: #fff8e1; }
.bg-amber-50 { background-color: #fff8e1; }
.border-amber-50 { border-color: #fff8e1; }
.fill-amber-50 { fill: #fff8e1; }
.stroke-amber-50 { stroke: #fff8e1; }
.color-amber-100 { color: #ffecb3; }
.bg-amber-100 { background-color: #ffecb3; }
.border-amber-100 { border-color: #ffecb3; }
.fill-amber-100 { fill: #ffecb3; }
.stroke-amber-100 { stroke: #ffecb3; }
.color-amber-200 { color: #ffe082; }
.bg-amber-200 { background-color: #ffe082; }
.border-amber-200 { border-color: #ffe082; }
.fill-amber-200 { fill: #ffe082; }
.stroke-amber-200 { stroke: #ffe082; }
.color-amber-300 { color: #ffd54f; }
.bg-amber-300 { background-color: #ffd54f; }
.border-amber-300 { border-color: #ffd54f; }
.fill-amber-300 { fill: #ffd54f; }
.stroke-amber-300 { stroke: #ffd54f; }
.color-amber-400 { color: #ffca28; }
.bg-amber-400 { background-color: #ffca28; }
.border-amber-400 { border-color: #ffca28; }
.fill-amber-400 { fill: #ffca28; }
.stroke-amber-400 { stroke: #ffca28; }
.color-amber-500 { color: #ffc107; }
.bg-amber-500 { background-color: #ffc107; }
.border-amber-500 { border-color: #ffc107; }
.fill-amber-500 { fill: #ffc107; }
.stroke-amber-500 { stroke: #ffc107; }
.color-amber-600 { color: #ffb300; }
.bg-amber-600 { background-color: #ffb300; }
.border-amber-600 { border-color: #ffb300; }
.fill-amber-600 { fill: #ffb300; }
.stroke-amber-600 { stroke: #ffb300; }
.color-amber-700 { color: #ffa000; }
.bg-amber-700 { background-color: #ffa000; }
.border-amber-700 { border-color: #ffa000; }
.fill-amber-700 { fill: #ffa000; }
.stroke-amber-700 { stroke: #ffa000; }
.color-amber-800 { color: #ff8f00; }
.bg-amber-800 { background-color: #ff8f00; }
.border-amber-800 { border-color: #ff8f00; }
.fill-amber-800 { fill: #ff8f00; }
.stroke-amber-800 { stroke: #ff8f00; }
.color-amber-900 { color: #ff6f00; }
.bg-amber-900 { background-color: #ff6f00; }
.border-amber-900 { border-color: #ff6f00; }
.fill-amber-900 { fill: #ff6f00; }
.stroke-amber-900 { stroke: #ff6f00; }
.color-amber-a100 { color: #ffe57f; }
.bg-amber-a100 { background-color: #ffe57f; }
.border-amber-a100 { border-color: #ffe57f; }
.fill-amber-a100 { fill: #ffe57f; }
.stroke-amber-a100 { stroke: #ffe57f; }
.color-amber-a200 { color: #ffd740; }
.bg-amber-a200 { background-color: #ffd740; }
.border-amber-a200 { border-color: #ffd740; }
.fill-amber-a200 { fill: #ffd740; }
.stroke-amber-a200 { stroke: #ffd740; }
.color-amber-a400 { color: #ffc400; }
.bg-amber-a400 { background-color: #ffc400; }
.border-amber-a400 { border-color: #ffc400; }
.fill-amber-a400 { fill: #ffc400; }
.stroke-amber-a400 { stroke: #ffc400; }
.color-amber-a700 { color: #ffab00; }
.bg-amber-a700 { background-color: #ffab00; }
.border-amber-a700 { border-color: #ffab00; }
.fill-amber-a700 { fill: #ffab00; }
.stroke-amber-a700 { stroke: #ffab00; }


.color-orange-50 { color: #fff3e0; }
.bg-orange-50 { background-color: #fff3e0; }
.border-orange-50 { border-color: #fff3e0; }
.fill-orange-50 { fill: #fff3e0; }
.stroke-orange-50 { stroke: #fff3e0; }
.color-orange-100 { color: #ffe0b2; }
.bg-orange-100 { background-color: #ffe0b2; }
.border-orange-100 { border-color: #ffe0b2; }
.fill-orange-100 { fill: #ffe0b2; }
.stroke-orange-100 { stroke: #ffe0b2; }
.color-orange-200 { color: #ffcc80; }
.bg-orange-200 { background-color: #ffcc80; }
.border-orange-200 { border-color: #ffcc80; }
.fill-orange-200 { fill: #ffcc80; }
.stroke-orange-200 { stroke: #ffcc80; }
.color-orange-300 { color: #ffb74d; }
.bg-orange-300 { background-color: #ffb74d; }
.border-orange-300 { border-color: #ffb74d; }
.fill-orange-300 { fill: #ffb74d; }
.stroke-orange-300 { stroke: #ffb74d; }
.color-orange-400 { color: #ffa726; }
.bg-orange-400 { background-color: #ffa726; }
.border-orange-400 { border-color: #ffa726; }
.fill-orange-400 { fill: #ffa726; }
.stroke-orange-400 { stroke: #ffa726; }
.color-orange-500 { color: #ff9800; }
.bg-orange-500 { background-color: #ff9800; }
.border-orange-500 { border-color: #ff9800; }
.fill-orange-500 { fill: #ff9800; }
.stroke-orange-500 { stroke: #ff9800; }
.color-orange-600 { color: #fb8c00; }
.bg-orange-600 { background-color: #fb8c00; }
.border-orange-600 { border-color: #fb8c00; }
.fill-orange-600 { fill: #fb8c00; }
.stroke-orange-600 { stroke: #fb8c00; }
.color-orange-700 { color: #f57c00; }
.bg-orange-700 { background-color: #f57c00; }
.border-orange-700 { border-color: #f57c00; }
.fill-orange-700 { fill: #f57c00; }
.stroke-orange-700 { stroke: #f57c00; }
.color-orange-800 { color: #ef6c00; }
.bg-orange-800 { background-color: #ef6c00; }
.border-orange-800 { border-color: #ef6c00; }
.fill-orange-800 { fill: #ef6c00; }
.stroke-orange-800 { stroke: #ef6c00; }
.color-orange-900 { color: #e65100; }
.bg-orange-900 { background-color: #e65100; }
.border-orange-900 { border-color: #e65100; }
.fill-orange-900 { fill: #e65100; }
.stroke-orange-900 { stroke: #e65100; }
.color-orange-a100 { color: #ffd180; }
.bg-orange-a100 { background-color: #ffd180; }
.border-orange-a100 { border-color: #ffd180; }
.fill-orange-a100 { fill: #ffd180; }
.stroke-orange-a100 { stroke: #ffd180; }
.color-orange-a200 { color: #ffab40; }
.bg-orange-a200 { background-color: #ffab40; }
.border-orange-a200 { border-color: #ffab40; }
.fill-orange-a200 { fill: #ffab40; }
.stroke-orange-a200 { stroke: #ffab40; }
.color-orange-a400 { color: #ff9100; }
.bg-orange-a400 { background-color: #ff9100; }
.border-orange-a400 { border-color: #ff9100; }
.fill-orange-a400 { fill: #ff9100; }
.stroke-orange-a400 { stroke: #ff9100; }
.color-orange-a700 { color: #ff6d00; }
.bg-orange-a700 { background-color: #ff6d00; }
.border-orange-a700 { border-color: #ff6d00; }
.fill-orange-a700 { fill: #ff6d00; }
.stroke-orange-a700 { stroke: #ff6d00; }


.color-deep-orange-50 { color: #fbe9e7; }
.bg-deep-orange-50 { background-color: #fbe9e7; }
.border-deep-orange-50 { border-color: #fbe9e7; }
.fill-deep-orange-50 { fill: #fbe9e7; }
.stroke-deep-orange-50 { stroke: #fbe9e7; }
.color-deep-orange-100 { color: #ffccbc; }
.bg-deep-orange-100 { background-color: #ffccbc; }
.border-deep-orange-100 { border-color: #ffccbc; }
.fill-deep-orange-100 { fill: #ffccbc; }
.stroke-deep-orange-100 { stroke: #ffccbc; }
.color-deep-orange-200 { color: #ffab91; }
.bg-deep-orange-200 { background-color: #ffab91; }
.border-deep-orange-200 { border-color: #ffab91; }
.fill-deep-orange-200 { fill: #ffab91; }
.stroke-deep-orange-200 { stroke: #ffab91; }
.color-deep-orange-300 { color: #ff8a65; }
.bg-deep-orange-300 { background-color: #ff8a65; }
.border-deep-orange-300 { border-color: #ff8a65; }
.fill-deep-orange-300 { fill: #ff8a65; }
.stroke-deep-orange-300 { stroke: #ff8a65; }
.color-deep-orange-400 { color: #ff7043; }
.bg-deep-orange-400 { background-color: #ff7043; }
.border-deep-orange-400 { border-color: #ff7043; }
.fill-deep-orange-400 { fill: #ff7043; }
.stroke-deep-orange-400 { stroke: #ff7043; }
.color-deep-orange-500 { color: #ff5722; }
.bg-deep-orange-500 { background-color: #ff5722; }
.border-deep-orange-500 { border-color: #ff5722; }
.fill-deep-orange-500 { fill: #ff5722; }
.stroke-deep-orange-500 { stroke: #ff5722; }
.color-deep-orange-600 { color: #f4511e; }
.bg-deep-orange-600 { background-color: #f4511e; }
.border-deep-orange-600 { border-color: #f4511e; }
.fill-deep-orange-600 { fill: #f4511e; }
.stroke-deep-orange-600 { stroke: #f4511e; }
.color-deep-orange-700 { color: #e64a19; }
.bg-deep-orange-700 { background-color: #e64a19; }
.border-deep-orange-700 { border-color: #e64a19; }
.fill-deep-orange-700 { fill: #e64a19; }
.stroke-deep-orange-700 { stroke: #e64a19; }
.color-deep-orange-800 { color: #d84315; }
.bg-deep-orange-800 { background-color: #d84315; }
.border-deep-orange-800 { border-color: #d84315; }
.fill-deep-orange-800 { fill: #d84315; }
.stroke-deep-orange-800 { stroke: #d84315; }
.color-deep-orange-900 { color: #bf360c; }
.bg-deep-orange-900 { background-color: #bf360c; }
.border-deep-orange-900 { border-color: #bf360c; }
.fill-deep-orange-900 { fill: #bf360c; }
.stroke-deep-orange-900 { stroke: #bf360c; }
.color-deep-orange-a100 { color: #ff9e80; }
.bg-deep-orange-a100 { background-color: #ff9e80; }
.border-deep-orange-a100 { border-color: #ff9e80; }
.fill-deep-orange-a100 { fill: #ff9e80; }
.stroke-deep-orange-a100 { stroke: #ff9e80; }
.color-deep-orange-a200 { color: #ff6e40; }
.bg-deep-orange-a200 { background-color: #ff6e40; }
.border-deep-orange-a200 { border-color: #ff6e40; }
.fill-deep-orange-a200 { fill: #ff6e40; }
.stroke-deep-orange-a200 { stroke: #ff6e40; }
.color-deep-orange-a400 { color: #ff3d00; }
.bg-deep-orange-a400 { background-color: #ff3d00; }
.border-deep-orange-a400 { border-color: #ff3d00; }
.fill-deep-orange-a400 { fill: #ff3d00; }
.stroke-deep-orange-a400 { stroke: #ff3d00; }
.color-deep-orange-a700 { color: #dd2c00; }
.bg-deep-orange-a700 { background-color: #dd2c00; }
.border-deep-orange-a700 { border-color: #dd2c00; }
.fill-deep-orange-a700 { fill: #dd2c00; }
.stroke-deep-orange-a700 { stroke: #dd2c00; }


.color-brown-50 { color: #efebe9; }
.bg-brown-50 { background-color: #efebe9; }
.border-brown-50 { border-color: #efebe9; }
.fill-brown-50 { fill: #efebe9; }
.stroke-brown-50 { stroke: #efebe9; }
.color-brown-100 { color: #d7ccc8; }
.bg-brown-100 { background-color: #d7ccc8; }
.border-brown-100 { border-color: #d7ccc8; }
.fill-brown-100 { fill: #d7ccc8; }
.stroke-brown-100 { stroke: #d7ccc8; }
.color-brown-200 { color: #bcaaa4; }
.bg-brown-200 { background-color: #bcaaa4; }
.border-brown-200 { border-color: #bcaaa4; }
.fill-brown-200 { fill: #bcaaa4; }
.stroke-brown-200 { stroke: #bcaaa4; }
.color-brown-300 { color: #a1887f; }
.bg-brown-300 { background-color: #a1887f; }
.border-brown-300 { border-color: #a1887f; }
.fill-brown-300 { fill: #a1887f; }
.stroke-brown-300 { stroke: #a1887f; }
.color-brown-400 { color: #8d6e63; }
.bg-brown-400 { background-color: #8d6e63; }
.border-brown-400 { border-color: #8d6e63; }
.fill-brown-400 { fill: #8d6e63; }
.stroke-brown-400 { stroke: #8d6e63; }
.color-brown-500 { color: #795548; }
.bg-brown-500 { background-color: #795548; }
.border-brown-500 { border-color: #795548; }
.fill-brown-500 { fill: #795548; }
.stroke-brown-500 { stroke: #795548; }
.color-brown-600 { color: #6d4c41; }
.bg-brown-600 { background-color: #6d4c41; }
.border-brown-600 { border-color: #6d4c41; }
.fill-brown-600 { fill: #6d4c41; }
.stroke-brown-600 { stroke: #6d4c41; }
.color-brown-700 { color: #5d4037; }
.bg-brown-700 { background-color: #5d4037; }
.border-brown-700 { border-color: #5d4037; }
.fill-brown-700 { fill: #5d4037; }
.stroke-brown-700 { stroke: #5d4037; }
.color-brown-800 { color: #4e342e; }
.bg-brown-800 { background-color: #4e342e; }
.border-brown-800 { border-color: #4e342e; }
.fill-brown-800 { fill: #4e342e; }
.stroke-brown-800 { stroke: #4e342e; }
.color-brown-900 { color: #3e2723; }
.bg-brown-900 { background-color: #3e2723; }
.border-brown-900 { border-color: #3e2723; }
.fill-brown-900 { fill: #3e2723; }
.stroke-brown-900 { stroke: #3e2723; }


.color-grey-50 { color: #fafafa; }
.bg-grey-50 { background-color: #fafafa; }
.border-grey-50 { border-color: #fafafa; }
.fill-grey-50 { fill: #fafafa; }
.stroke-grey-50 { stroke: #fafafa; }
.color-grey-100 { color: #f5f5f5; }
.bg-grey-100 { background-color: #f5f5f5; }
.border-grey-100 { border-color: #f5f5f5; }
.fill-grey-100 { fill: #f5f5f5; }
.stroke-grey-100 { stroke: #f5f5f5; }
.color-grey-200 { color: #eeeeee; }
.bg-grey-200 { background-color: #eeeeee; }
.border-grey-200 { border-color: #eeeeee; }
.fill-grey-200 { fill: #eeeeee; }
.stroke-grey-200 { stroke: #eeeeee; }
.color-grey-300 { color: #e0e0e0; }
.bg-grey-300 { background-color: #e0e0e0; }
.border-grey-300 { border-color: #e0e0e0; }
.fill-grey-300 { fill: #e0e0e0; }
.stroke-grey-300 { stroke: #e0e0e0; }
.color-grey-400 { color: #bdbdbd; }
.bg-grey-400 { background-color: #bdbdbd; }
.border-grey-400 { border-color: #bdbdbd; }
.fill-grey-400 { fill: #bdbdbd; }
.stroke-grey-400 { stroke: #bdbdbd; }
.color-grey-500 { color: #9e9e9e; }
.bg-grey-500 { background-color: #9e9e9e; }
.border-grey-500 { border-color: #9e9e9e; }
.fill-grey-500 { fill: #9e9e9e; }
.stroke-grey-500 { stroke: #9e9e9e; }
.color-grey-600 { color: #757575; }
.bg-grey-600 { background-color: #757575; }
.border-grey-600 { border-color: #757575; }
.fill-grey-600 { fill: #757575; }
.stroke-grey-600 { stroke: #757575; }
.color-grey-700 { color: #616161; }
.bg-grey-700 { background-color: #616161; }
.border-grey-700 { border-color: #616161; }
.fill-grey-700 { fill: #616161; }
.stroke-grey-700 { stroke: #616161; }
.color-grey-800 { color: #424242; }
.bg-grey-800 { background-color: #424242; }
.border-grey-800 { border-color: #424242; }
.fill-grey-800 { fill: #424242; }
.stroke-grey-800 { stroke: #424242; }
.color-grey-900 { color: #212121; }
.bg-grey-900 { background-color: #212121; }
.border-grey-900 { border-color: #212121; }
.fill-grey-900 { fill: #212121; }
.stroke-grey-900 { stroke: #212121; }


.color-blue-grey-50 { color: #eceff1; }
.bg-blue-grey-50 { background-color: #eceff1; }
.border-blue-grey-50 { border-color: #eceff1; }
.fill-blue-grey-50 { fill: #eceff1; }
.stroke-blue-grey-50 { stroke: #eceff1; }
.color-blue-grey-100 { color: #cfd8dc; }
.bg-blue-grey-100 { background-color: #cfd8dc; }
.border-blue-grey-100 { border-color: #cfd8dc; }
.fill-blue-grey-100 { fill: #cfd8dc; }
.stroke-blue-grey-100 { stroke: #cfd8dc; }
.color-blue-grey-200 { color: #b0bec5; }
.bg-blue-grey-200 { background-color: #b0bec5; }
.border-blue-grey-200 { border-color: #b0bec5; }
.fill-blue-grey-200 { fill: #b0bec5; }
.stroke-blue-grey-200 { stroke: #b0bec5; }
.color-blue-grey-300 { color: #90a4ae; }
.bg-blue-grey-300 { background-color: #90a4ae; }
.border-blue-grey-300 { border-color: #90a4ae; }
.fill-blue-grey-300 { fill: #90a4ae; }
.stroke-blue-grey-300 { stroke: #90a4ae; }
.color-blue-grey-400 { color: #78909c; }
.bg-blue-grey-400 { background-color: #78909c; }
.border-blue-grey-400 { border-color: #78909c; }
.fill-blue-grey-400 { fill: #78909c; }
.stroke-blue-grey-400 { stroke: #78909c; }
.color-blue-grey-500 { color: #607d8b; }
.bg-blue-grey-500 { background-color: #607d8b; }
.border-blue-grey-500 { border-color: #607d8b; }
.fill-blue-grey-500 { fill: #607d8b; }
.stroke-blue-grey-500 { stroke: #607d8b; }
.color-blue-grey-600 { color: #546e7a; }
.bg-blue-grey-600 { background-color: #546e7a; }
.border-blue-grey-600 { border-color: #546e7a; }
.fill-blue-grey-600 { fill: #546e7a; }
.stroke-blue-grey-600 { stroke: #546e7a; }
.color-blue-grey-700 { color: #455a64; }
.bg-blue-grey-700 { background-color: #455a64; }
.border-blue-grey-700 { border-color: #455a64; }
.fill-blue-grey-700 { fill: #455a64; }
.stroke-blue-grey-700 { stroke: #455a64; }
.color-blue-grey-800 { color: #37474f; }
.bg-blue-grey-800 { background-color: #37474f; }
.border-blue-grey-800 { border-color: #37474f; }
.fill-blue-grey-800 { fill: #37474f; }
.stroke-blue-grey-800 { stroke: #37474f; }
.color-blue-grey-900 { color: #263238; }
.bg-blue-grey-900 { background-color: #263238; }
.border-blue-grey-900 { border-color: #263238; }
.fill-blue-grey-900 { fill: #263238; }
.stroke-blue-grey-900 { stroke: #263238; }


.color-dark-text-primary { color: rgba(0, 0, 0, 0.87); }
.bg-dark-text-primary { background-color: rgba(0, 0, 0, 0.87); }
.border-dark-text-primary { border-color: rgba(0, 0, 0, 0.87); }
.fill-dark-text-primary { fill: rgba(0, 0, 0, 0.87); }
.stroke-dark-text-primary { stroke: rgba(0, 0, 0, 0.87); }
.color-dark-text-secondary { color: rgba(0, 0, 0, 0.54); }
.bg-dark-text-secondary { background-color: rgba(0, 0, 0, 0.54); }
.border-dark-text-secondary { border-color: rgba(0, 0, 0, 0.54); }
.fill-dark-text-secondary { fill: rgba(0, 0, 0, 0.54); }
.stroke-dark-text-secondary { stroke: rgba(0, 0, 0, 0.54); }
.color-dark-text-disabled { color: rgba(0, 0, 0, 0.38); }
.bg-dark-text-disabled { background-color: rgba(0, 0, 0, 0.38); }
.border-dark-text-disabled { border-color: rgba(0, 0, 0, 0.38); }
.fill-dark-text-disabled { fill: rgba(0, 0, 0, 0.38); }
.stroke-dark-text-disabled { stroke: rgba(0, 0, 0, 0.38); }
.color-dark-text-dividers { color: rgba(0, 0, 0, 0.12); }
.bg-dark-text-dividers { background-color: rgba(0, 0, 0, 0.12); }
.border-dark-text-dividers { border-color: rgba(0, 0, 0, 0.12); }
.fill-dark-text-dividers { fill: rgba(0, 0, 0, 0.12); }
.stroke-dark-text-dividers { stroke: rgba(0, 0, 0, 0.12); }


.color-light-text-primary { color: rgba(255, 255, 255, 1); }
.bg-light-text-primary { background-color: rgba(255, 255, 255, 1); }
.border-light-text-primary { border-color: rgba(255, 255, 255, 1); }
.fill-light-text-primary { fill: rgba(255, 255, 255, 1); }
.stroke-light-text-primary { stroke: rgba(255, 255, 255, 1); }
.color-light-text-secondary { color: rgba(255, 255, 255, 0.7); }
.bg-light-text-secondary { background-color: rgba(255, 255, 255, 0.7); }
.border-light-text-secondary { border-color: rgba(255, 255, 255, 0.7); }
.fill-light-text-secondary { fill: rgba(255, 255, 255, 0.7); }
.stroke-light-text-secondary { stroke: rgba(255, 255, 255, 0.7); }
.color-light-text-disabled { color: rgba(255, 255, 255, 0.5); }
.bg-light-text-disabled { background-color: rgba(255, 255, 255, 0.5); }
.border-light-text-disabled { border-color: rgba(255, 255, 255, 0.5); }
.fill-light-text-disabled { fill: rgba(255, 255, 255, 0.5); }
.stroke-light-text-disabled { stroke: rgba(255, 255, 255, 0.5); }
.color-light-text-dividers { color: rgba(255, 255, 255, 0.12); }
.bg-light-text-dividers { background-color: rgba(255, 255, 255, 0.12); }
.border-light-text-dividers { border-color: rgba(255, 255, 255, 0.12); }
.fill-light-text-dividers { fill: rgba(255, 255, 255, 0.12); }
.stroke-light-text-dividers { stroke: rgba(255, 255, 255, 0.12); }


.color-dark-icons-active { color: rgba(0, 0, 0, 0.54); }
.bg-dark-icons-active { background-color: rgba(0, 0, 0, 0.54); }
.border-dark-icons-active { border-color: rgba(0, 0, 0, 0.54); }
.fill-dark-icons-active { fill: rgba(0, 0, 0, 0.54); }
.stroke-dark-icons-active { stroke: rgba(0, 0, 0, 0.54); }
.color-dark-icons-inactive { color: rgba(0, 0, 0, 0.38); }
.bg-dark-icons-inactive { background-color: rgba(0, 0, 0, 0.38); }
.border-dark-icons-inactive { border-color: rgba(0, 0, 0, 0.38); }
.fill-dark-icons-inactive { fill: rgba(0, 0, 0, 0.38); }
.stroke-dark-icons-inactive { stroke: rgba(0, 0, 0, 0.38); }


.color-light-icons-active { color: rgba(255, 255, 255, 1); }
.bg-light-icons-active { background-color: rgba(255, 255, 255, 1); }
.border-light-icons-active { border-color: rgba(255, 255, 255, 1); }
.fill-light-icons-active { fill: rgba(255, 255, 255, 1); }
.stroke-light-icons-active { stroke: rgba(255, 255, 255, 1); }
.color-light-icons-inactive { color: rgba(255, 255, 255, 0.5); }
.bg-light-icons-inactive { background-color: rgba(255, 255, 255, 0.5); }
.border-light-icons-inactive { border-color: rgba(255, 255, 255, 0.5); }
.fill-light-icons-inactive { fill: rgba(255, 255, 255, 0.5); }
.stroke-light-icons-inactive { stroke: rgba(255, 255, 255, 0.5); }


.color-white { color: #ffffff; }
.bg-white { background-color: #ffffff; }
.border-white { border-color: #ffffff; }
.fill-white { fill: #ffffff; }
.stroke-white { stroke: #ffffff; }


.color-black { color: #000000; }
.bg-black { background-color: #000000; }
.border-black { border-color: #000000; }
.fill-black { fill: #000000; }
.stroke-black { stroke: #000000; }



